import React, { useState, useRef, useEffect } from "react";
import { SendIcon, ThumbsUp, Trash2, RotateCcw } from 'lucide-react';

const App = () => {
  const [messages, setMessages] = useState(() => {
    const saved = localStorage.getItem('chatMessages');
    return saved ? JSON.parse(saved) : [];
  });
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [ratedMessages, setRatedMessages] = useState({});
  const [thankedMessages, setThankedMessages] = useState({});
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Add CSS to prevent zoom on mobile devices and for the thank animation
    const style = document.createElement('style');
    style.textContent = `
      @media (max-width: 767px) {
        input, textarea {
          font-size: 16px !important;
        }
      }
      .thank-animation {
        animation: fadeOut 2s forwards;
      }
      @keyframes fadeOut {
        0% { opacity: 1; }
        70% { opacity: 1; }
        100% { opacity: 0; }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Guardar mensajes en localStorage
  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  // Función para limpiar el historial
  const clearHistory = () => {
    if (window.confirm('¿Estás seguro de que deseas borrar todo el historial?')) {
      setMessages([]);
    }
  };

  // Función para reintentar el último mensaje
  const retryLastMessage = () => {
    if (messages.length > 0) {
      const lastUserMessage = messages.filter(m => m.role === 'user').pop();
      if (lastUserMessage) {
        setInput(lastUserMessage.content);
        sendMessage();
      }
    }
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: "user", content: input };
    setMessages(prev => [...prev, userMessage]);
    setInput("");
    setIsTyping(true);

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // 10s timeout

      const response = await fetch("https://backend.zipply.app/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ pregunta: input }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }

      const data = await response.json();
      setMessages(prev => [...prev, { 
        role: "assistant", 
        content: data.respuesta, 
        id: data.messageId 
      }]);
    } catch (error) {
      const errorMessage = error.name === 'AbortError' 
        ? "La solicitud tardó demasiado tiempo. Por favor, intenta de nuevo."
        : "Error al conectar con el servidor. Por favor, intenta de nuevo más tarde.";

      setMessages(prev => [...prev, {
        role: "assistant",
        content: errorMessage,
        id: Date.now(),
        isError: true
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  const rateMessage = async (messageId) => {
    if (ratedMessages[messageId]) return;

    try {
        const response = await fetch("https://backend.zipply.app/rate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ messageId, rating: "good" }),
        });

        if (response.ok) {
            setRatedMessages({ ...ratedMessages, [messageId]: true });
            setThankedMessages({ ...thankedMessages, [messageId]: true });

            // Remove the "Thanks!" message after animation
            setTimeout(() => {
                setThankedMessages((prev) => ({ ...prev, [messageId]: false }));
            }, 2000);
        } else {
            console.error("Error al enviar la calificación");
        }
    } catch (error) {
        console.error("Error al conectar con el backend:", error);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-[#1a1a1a]">
      <header className="flex items-center justify-between bg-[#0f0f0f] px-4 py-3 border-b border-gray-800">
        <div className="flex items-center">
          <button className="p-2 hover:bg-gray-800 rounded-md md:hidden">
            <svg className="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <h1 className="text-white text-lg font-semibold ml-2">UNAVirtual</h1>
        </div>
        <div className="flex gap-2">
          <button
            onClick={retryLastMessage}
            className="p-2 hover:bg-gray-800 rounded-md text-gray-400 hover:text-white"
            title="Reintentar último mensaje"
          >
            <RotateCcw size={18} />
          </button>
          <button
            onClick={clearHistory}
            className="p-2 hover:bg-gray-800 rounded-md text-gray-400 hover:text-white"
            title="Limpiar historial"
          >
            <Trash2 size={18} />
          </button>
        </div>
      </header>

      <div className="flex-1 overflow-y-auto">
        <div className="max-w-3xl mx-auto px-4 py-8 space-y-6">
          {messages.length === 0 && (
            <div className="text-center">
              <h2 className="text-2xl font-semibold text-white mb-8">¿Con qué puedo ayudarte?</h2>
            </div>
          )}

          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.role === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`max-w-[85%] rounded-2xl px-4 py-3 ${
                  message.role === "user"
                    ? "bg-[#1a1c77] text-white"
                    : "bg-[#2a2a2a] text-gray-100"
                }`}
              >
                <div className="font-medium mb-1 text-sm">
                  {message.role === "user" ? "Usuario" : "UNACHI"}
                </div>
                <div className="whitespace-pre-wrap text-sm">{message.content}</div>
                {message.role === "assistant" && !ratedMessages[message.id] && (
                  <button
                    onClick={() => rateMessage(message.id)}
                    className="mt-2 flex items-center text-xs text-gray-400 hover:text-white transition-colors duration-200"
                  >
                    <ThumbsUp size={14} className="mr-1" />
                    Calificar como buena respuesta
                  </button>
                )}
                {thankedMessages[message.id] && (
                  <div className="mt-2 text-xs text-green-500 thank-animation">
                    ¡Gracias por tu calificación!
                  </div>
                )}
              </div>
            </div>
          ))}
          {isTyping && (
            <div className="flex justify-start">
              <div className="max-w-[85%] rounded-2xl px-4 py-3 bg-[#2a2a2a] text-gray-100">
                <div className="font-medium mb-1 text-sm">UNACHI</div>
                <div className="text-sm">Está escribiendo...</div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="border-t border-gray-800 bg-[#1a1a1a] px-4 py-4">
        <div className="max-w-3xl mx-auto">
          <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }} className="relative">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && sendMessage()}
              className="w-full bg-[#2a2a2a] text-white rounded-xl px-4 py-3 pr-12 focus:outline-none focus:ring-2 focus:ring-blue-500 text-base"
              placeholder="Envía un mensaje a UNAVirtual..."
            />
            <button
              type="submit"
              disabled={!input.trim()}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-white disabled:opacity-40 disabled:hover:text-gray-400"
            >
              <SendIcon size={18} />
            </button>
          </form>
          <p className="text-xs text-center mt-2 text-gray-500">
            UNAVirtual puede cometer errores. Comprueba la información importante. De encontrar informacion incorrecta reportar a DTIC.
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;
